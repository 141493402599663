/*======================
			Category @class
======================*/

export default class Category {
	public color: string;
	public name: string;
	public id: number;

	constructor(settings) {
		// Init variables
		
		this.color = settings.color || '#333333';
		this.name = settings.name || '';
		this.id = settings.id || '';
		
	}
}
