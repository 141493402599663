import Editor from './editor/editor';
import Sidebar from './sidebar/sidebar';

let editor;
let sidebar;


document.addEventListener("DOMContentLoaded", init);

function init(){
	editor = new Editor();
	sidebar = new Sidebar({editor});
}