import Category from './category';

/*======================
			Seat @class
======================*/

export default class Seat {
	private id: string;
	private class: string;
	public x: number;
	public y: number;

	private fill: string;
	public  radius: number;
	public  element: any;
	private strokeWidth: number;
	private strokeColor: string;
	private opacity: number;
	private category: number;
	
	private selected: boolean;
	private row: boolean;
	private seat: boolean;
	public  removing: boolean; // флаг на удаление

	constructor(settings) {
		// Init variables
		this.id          = settings.id;
		this.class       = settings.class || "";
		this.x           = settings.x;
		this.y           = settings.y;

		this.radius      = settings.radius      || 8;
		this.strokeWidth = settings.strokeWidth || 0;
		this.strokeColor = settings.strokeColor || "#75c40e";//"#333333";
		this.fill        = settings.fill        || "#75c40e";
		this.opacity     = settings.opacity     || 1;
		this.category    = settings.category    || 1;
		
		this.row         = settings.row         || 1;
		this.seat        = settings.seat        || 1;

		this.selected    = false;
		this.removing    = false;

		this.element = settings.target.circle(this.x, this.y, this.radius);
		this.element.attr({
			// 'id': this.id,
			'fill': this.fill,
			// 'stroke': this.strokeColor,
			// 'stroke-width': this.strokeWidth,
			// 'fill-opacity': this.opacity,
			// 'stroke-opacity': this.opacity,
			'class': 'seat' + this.class,
			'data-category': this.category,
			'data-row': this.row,
			'data-seat': this.seat
		});
	}

	public muffle(muffle: boolean){
		this.element.attr('stroke-opacity', muffle ? 0.5: 1);
		this.element.attr('fill-opacity', muffle ? 0.5: 1);
	}

	public move(x, y): any{
		this.element.attr('cx', this.x += x);
		this.element.attr('cy', this.y += y);
	}

	public destroy(): any{
		this.element.remove();
		//dont forget remove seat:Seat like this: delete seat from seats array
	}
	public clone(): Seat{
		return this;
	}
	public applyMatrix(){
		if(this.element.attr('transform').toString() != ''){
			let matrix = this.element.attr('transform').toString().slice(1).split(',');
			this.element.attr('transform', '');
			this.move(+matrix[0], +matrix[1]);
		}
	}
	public getBBox(){
		return this.element.getBBox();
	}
		
}
