/*======================
			Sidebar @class
======================*/

export default class Sidebar {

	constructor(settings) {
		// Init variables
		
		let editor = settings.editor;
		
		let modeButtons = <NodeListOf<Element>>document.querySelectorAll('.sidebar__tool_btn');

		document.addEventListener('click', function(event: MouseEvent){
			let target = <HTMLElement>event.target;

			// клик по кнопке смены текущего инструмента / состояния
			if(target.classList.contains('sidebar__tool_btn')){
				for(let i = 0; i < modeButtons.length; i++){
					modeButtons[i].classList.remove('active');
				}
				
				target.classList.add('active');
				let value = <String>target.getAttribute('value');
				editor.setMode(value);
			}
			
			// если есть селектор мест -- показать селектор категорий
			if (document.querySelector('#selected__outline')) {
				editor.showSelectControlls();
			}
			
			if(target.classList.contains('sidebar__zoom-btn')){
				
				let zoom = editor.zoom(<String>target.getAttribute('value'));
				// console.log(<String>target.getAttribute('value'))
				
				if(zoom > 2) (<HTMLElement>document.querySelector('.zoom-more')).setAttribute('disabled','disabled');
				else if(zoom <= 2) (<HTMLElement>document.querySelector('.zoom-more')).removeAttribute('disabled');
				if(zoom < 0.2) (<HTMLElement>document.querySelector('.zoom-less')).setAttribute('disabled','disabled');
				else if(zoom >= 0.2) (<HTMLElement>document.querySelector('.zoom-less')).removeAttribute('disabled');
			}
		});
		
		// ставим активный по умолчанию 
		(<HTMLElement>document.querySelector('.sidebar__tool_btn.active')).click();
		
		// Смена активной категории
		var categories = (<HTMLSelectElement>document.querySelector("#categories"));
		categories.addEventListener('change', function(event: Event){
			//editor.setCategory(this.value)
			
			let active = (<HTMLElement>this.querySelector('option.active'))
			if (active) active.classList.remove('active');
			(<HTMLElement>this.querySelectorAll('option')[this.selectedIndex]).classList.add('active');
			
			// вставляем контент в форму редактирования категории
			let form = <HTMLElement>document.querySelector(".edit-category-form");
			let category = <HTMLElement>document.querySelector("#categories option.active");
			
			
			(<HTMLInputElement>form.querySelector('[name="id"]')).value = category.getAttribute("data-id") || "";
			(<HTMLInputElement>form.querySelector('[name="name"]')).value = category.getAttribute("data-name") || "";
			(<HTMLInputElement>form.querySelector('[name="color"]')).value = category.getAttribute("data-color") || "";
			
			editor.setActiveCategory({
				id: category.getAttribute("data-id") || "", 
				name: category.getAttribute("data-name") || "", 
				color: category.getAttribute("data-color") || ""
			});
		});

		categories.addEventListener('update', function (event: Event) {
			categories.innerHTML = ''

			for (let category of editor.categories.list) {
				var option = document.createElement('option');
				option.innerHTML = category.name
				option.setAttribute('data-id', '' + category.id)
				option.setAttribute('data-name', category.name)
				option.setAttribute('data-color', category.color)
				option.setAttribute('class', '')

				categories.appendChild(option);
			}
		})
		
		categories.dispatchEvent(new Event ('change'));
		
		// добавление категории
		(<HTMLSelectElement>document.querySelector(".add-category-form")).addEventListener('submit', function(event: Event){
			let category = {
				id: (<HTMLInputElement>this.querySelector('[name="id"]')).value,
				name: (<HTMLInputElement>this.querySelector('[name="name"]')).value,
				color: (<HTMLInputElement>this.querySelector('[name="color"]')).value
			}
			
			
			if (document.querySelector('#categories option[data-id="' + category.id + '"]') != null) return;
			
			let categories = <HTMLSelectElement>document.querySelector("#categories");
			let option = document.createElement('option');
			
			option.setAttribute('data-id', category.id);
			option.setAttribute('data-name', category.name);
			option.setAttribute('data-color', category.color);
			
			
			option.dispatchEvent(new MouseEvent('click'))
			option.dispatchEvent(new MouseEvent('click'))
			
			option.textContent = category.name;
			
			categories.appendChild(option);
			
			editor.addCategory(category);
			
			(<HTMLInputElement>this.querySelector('[name="id"]')).value = "";
			(<HTMLInputElement>this.querySelector('[name="name"]')).value = "";
			(<HTMLInputElement>this.querySelector('[name="color"]')).value = "#b7b7b7";
		});
		
		// Редактирование категории
		(<HTMLSelectElement>document.querySelector(".edit-category-form")).addEventListener('submit', function(event: Event){
			let category = {
				id: (<HTMLInputElement>this.querySelector('[name="id"]')).value,
				name: (<HTMLInputElement>this.querySelector('[name="name"]')).value,
				color: (<HTMLInputElement>this.querySelector('[name="color"]')).value
			}
			
			let categories = <HTMLSelectElement>document.querySelector("#categories");
			let option = <HTMLElement>categories.querySelector('option.active');
			
			let oldCategory = {
				id: option.getAttribute('data-id') || "",
				name: option.getAttribute('data-name') || "",
				color: option.getAttribute('data-color') || ""
			}
			
			option.setAttribute('data-id', category.id);
			option.setAttribute('data-name', category.name);
			option.setAttribute('data-color', category.color);
			
			option.textContent = category.name;
			
			editor.editCategory(oldCategory, category);
		});
		
		// переключение табов в категориях (добавить / редактировать)
		var tabs = <HTMLElement>document.querySelector('.sidebar__categories-nav-tabs');
		
		tabs.addEventListener('click', function(event: MouseEvent){
			var target = <HTMLElement>event.target;
			if ( !target.classList.contains('sidebar__categories-nav-tabs-link') ) return;
			
			var href = target.getAttribute('data-tab') || "";
			
			(<HTMLElement>document.querySelector('.sidebar__categories-nav-tabs-link.active')).classList.remove('active');
			target.classList.add('active');
			
			(<HTMLElement>document.querySelector('.sidebar__categories-tab.active')).classList.remove('active');
			(<HTMLElement>document.querySelector( href )).classList.add('active');
		});
		
		
		
		
		// 
		(<HTMLElement>document.querySelector('.apply-to-select')).addEventListener('click', function(){
			let option = <HTMLElement>categories.querySelector('option.active');
			
			let category = {
				id: option.getAttribute('data-id') || "",
				name: option.getAttribute('data-name') || "",
				color: option.getAttribute('data-color') || ""
			}
		
			editor.applyCategoryToSelected(category)
		});
		
		// Применяем нумерацию
		(<HTMLElement>document.querySelector('.sidebar__numeration')).addEventListener('submit', function(){
			// var seatsVector = []; // вектор определяющий направление нумерации сидений
			// var rowsVector  = []; // вектор определяющий направление нумерации рядов
			
			this.classList.remove("valid");
			this.classList.remove("invalid");
			
			var seatsNumeration = (<HTMLSelectElement>this.querySelector('.numeration__seats')).value;
			var rowsNumeration  = (<HTMLSelectElement>this.querySelector('.numeration__rows')).value;
			
			var seatsNumerationFrom = (<HTMLInputElement>this.querySelector('.numeration__seats-start')).value || 1;
			var rowsNumerationFrom  = (<HTMLInputElement>this.querySelector('.numeration__rows-start')).value || 1;
			
			var seatsNumerationStep = (<HTMLInputElement>this.querySelector('.numeration__seats-step')).value || 1;
			var rowsNumerationStep  = (<HTMLInputElement>this.querySelector('.numeration__rows-step')).value || 1;

			switch ( seatsNumeration + "," + rowsNumeration ){
				case "1,3" : case "1,4" : case "2,3" : case "2,4" : 
				case "3,1" : case "4,1" : case "3,2" : case "4,2" : this.classList.add("valid"); break; // ок
				default : this.classList.add("invalid"); return; // not ok -> return
			}

			let that = this;

			setTimeout(function(){
				that.classList.remove("valid");
				that.classList.remove("invalid");
			}, 3000);
			
			editor.numerateSelectedSeats(+seatsNumeration, +rowsNumeration, +seatsNumerationFrom, +rowsNumerationFrom, +seatsNumerationStep, +rowsNumerationStep);
		});
		
		
		(<HTMLElement>document.querySelector('.sidebar__remove-button')).addEventListener('click', function(){
			editor.clearSelected(true);
		});
		
		
		let exportBtns = (<NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar__export-button'));
		for (let i=0; i < exportBtns.length; i++){
			let btn = exportBtns[i]; 
			
			btn.addEventListener('click', function(){
				editor.export(this.getAttribute('value'), 500, 500)
			});
		}
		
		(<HTMLElement>document.getElementById('svg-editor-canvas')).addEventListener('wheel', function(e){
			if(e.deltaY > 0) (<HTMLElement>document.querySelector('.zoom-less')).click();
			else if (e.deltaY < 0) (<HTMLElement>document.querySelector('.zoom-more')).click();	
		});
		
		(<HTMLElement>document.querySelector('.theme-switch-button')).addEventListener('click', function(){
			let opositeTheme = this.getAttribute('value') || '';
			let activeTheme = this.getAttribute('old') || '';
			
			document.body.classList.remove('theme-' + activeTheme);
			document.body.classList.add('theme-' + opositeTheme);
			
			this.setAttribute('value', activeTheme)
			this.setAttribute('old', opositeTheme)
		});
		
		


		
		
		
		
	}
	
	
	
}
