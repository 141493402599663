import Seat from '../abstract/seat';
import Category from '../abstract/category';
import Sidebar from '../sidebar/sidebar';
import * as Snap from 'snapsvg';
import * as PNotify from 'pnotify/dist/umd/PNotify.js';
import * as PNotifyButtons from 'pnotify/dist/umd/PNotifyButtons.js';
// import * as PNotifyStyleMaterial from 'pnotify/dist/umd/PNotifyStyleMaterial.js';
// 
PNotify.defaults.width = '300px';
PNotify.defaults.delay = 1000;

export default class Editor{
	private canvas: any;                            // Snap-объект на котором происходит вся отрисовка	
	private editor: HTMLElement;                    // HTML-объект редактора
	private mode: String;                           // активный режим / инструмент
	private grid: { size:number, active: boolean }; // параметры сетки
	private keysPressed: Array<boolean>;            // нажатые кнопки
	private seatInfo: HTMLElement;                  // блок с информацией о месте
	private gridInfo: HTMLElement;                  // блок с информацией о месте
	
	private seats: {
		list: Array<Seat>,                            // все активные сидения на схеме
		container: any,                               // Snap-объект группа в которой отрисованы все сидения
		actual: any,                                  // Snap-объект группа в которой отрисованы актуальные сидения
		temp: any,                                    // Snap-объект группа в которой отрисованы добавляемые сидения
		map: Array<boolean>,                          // карта позиций мест (нужна для устранения наслоений мест)
		count: number,                                // кол-во мест всего
		selected: {
			group: any,                                 // SNAP-группа выделенных мест
			border: any                                 // SNAP-объект селектор
		}
	}
	
	private categories: {
		list: Array<Category>,                        // массив категорий
		active: Category
	}
	
	private clickState: string;                     // состояние клика ["DOWN", "MOVE", "UP"], чтобы не дублировать обработку клика когда клик не завершён а фокус потерян
	
	// private importInput: HTMLElement;
	// window.URL.createObjectURL(document.querySelector('#import-input').files[0]) // вроде это даёт ссылку на локальный файл для загрузки

	constructor (){
		this.canvas = Snap("#svg-editor-canvas");
		
		// Создаём структуру объектов SVG
		this.canvas.g(
			this.canvas.g(	
				this.canvas.g().attr({id: "temp"}),
				this.canvas.g(
					this.canvas.g().attr({class: "selected"})
				).attr({id: "actual"})
			).attr({id: "seats"})
		).attr({id: "content"});
		
		this.seats = { 
			list: [],
			container: Snap("#svg-editor-canvas #seats"),
			actual: Snap("#svg-editor-canvas #seats #actual"),
			temp: Snap("#svg-editor-canvas #seats #temp"),
			map: [],
			count: 0,
			selected: {
				group: Snap("#svg-editor-canvas #seats #actual .selected"),
				border: Snap('#selected__outline')
			}
		}
		
		// создаём дефолтные категории
		let initialCategories = [
			new Category({ id: 1, name: "Standard", color: "#5ccc2b" }),
			new Category({ id: 2, name: "Business", color: "#ff9500" }),
			new Category({ id: 3, name: "VIP", color: "#1fb6b4" })
		];
		
		this.categories = {
			list: [ initialCategories[0], initialCategories[1], initialCategories[2] ],
			active: initialCategories[0]
		}
		
		this.keysPressed = [];
		this.mode = "SELECT";
		this.clickState = "UP";
		this.grid = { size: 25, active: true }
		
		this.editor = <HTMLElement>document.querySelector('.editor');
		this.seatInfo = <HTMLElement>document.querySelector('.seat-info');
		this.gridInfo = <HTMLElement>document.querySelector('.grid-info');

		// обрабатываем нажатие в зависимости от активного режима, передаём контекст this
		this.editor.addEventListener("mousedown", event => this.mousedown.call(this, event));
		
		// слушаем нажатые кнопки, передаём контекст this
		document.getElementsByTagName('body')[0].addEventListener("keydown", event => this.keydown.call(this, event));
		document.getElementsByTagName('body')[0].addEventListener("keyup", event => this.keyup.call(this, event));
		
		// ставим места в точку ровно по центру экрана
		this.seats.container.attr('transform', `matrix(1, 0, 0, 1, ${this.canvas.node.clientWidth / 2}, ${this.canvas.node.clientHeight / 2})`);
		this.fitBGDots();

		// обработка загрузки схемы
		(<HTMLElement>document.querySelector('#import-input')).addEventListener('change', event => this.uploadScheme.call(this, event))
	}
	
	// отслеживаем нажатые кнопки клавиатуры
	private keydown(event: KeyboardEvent){
	// console.log(event.keyCode)
		switch (event.keyCode){
			case 17: this.keysPressed['CTRL']   = true; break;
			case 32: this.keysPressed['SPACE']  = true; this.editor.classList.add('editor-grab'); break;
			case 37: this.keysPressed['LEFT']   = true; this.move('LEFT'); break;
			case 38: this.keysPressed['TOP']    = true; this.move('TOP'); break;
			case 39: this.keysPressed['RIGHT']  = true; this.move('RIGHT'); break;
			case 40: this.keysPressed['BOTTOM'] = true; this.move('BOTTOM'); break;
			case 16: this.keysPressed['SHIFT']  = true; break;
			case 46: this.keysPressed['DELETE'] = true; this.clearSelected(true); break;
		}
	}
	private keyup(event: KeyboardEvent){
		switch (event.keyCode){
			case 17: this.keysPressed['CTRL']   = false; break;
			case 32: this.keysPressed['SPACE']  = false; if(this.mode != 'HAND') this.editor.classList.remove('editor-grab'); break;
			case 37: this.keysPressed['LEFT']   = false; break; 
			case 38: this.keysPressed['TOP']    = false; break;
			case 39: this.keysPressed['RIGHT']  = false; break;
			case 40: this.keysPressed['BOTTOM'] = false; break;
			case 16: this.keysPressed['SHIFT']  = false; break;
			case 46: this.keysPressed['DELETE'] = false; break;
		}
	}
	
	
	/* -----------------------------------------------------------------
	Обработчик нажатия мыши (mousedown). Делегирует обработку на уровень
	ниже в зависимости от выбранного инструмента (ADD, SELECT, MOVE, ...)
	или зажатой клавиши (SPACE, CTRL, ...) 
	------------------------------------------------------------------*/
	private mousedown(event: MouseEvent) {
		
		// только клик левой кнопки
		if (event.which != 1) return;

		// не обрабатывать когда мышь не отжата
		if(this.clickState != "UP") return;
		
		// состояние мыши: "зажата"
		this.clickState = "DOWN";
		
		// если зажат пробел - двигаться по схеме
		if(this.keysPressed['SPACE']){ this.reactHand(event); return; }
		
		// если клик по выделению -- двигать, иначе -- снять выделение
		if((<HTMLElement>event.target).id == 'selected__outline'){ this.reactMove(event); return; }
		else { 
			if(this.mode != "HAND")
				this.clearSelected();
		}
		
		// обработчики инструментов (режимов)
		switch (this.mode){
			case "ADD":    this.reactAdd(event);    break; // обработчик добавления
			case "SELECT": this.reactSelect(event); break; // обработчик выделения
			case "HAND":   this.reactHand(event);   break; // обработчик руки
		}		
	}
	
	/* -----------------------------------------------------------------
	Обработчик добавления мест на схему. Добавляет одно или несколько
	мест по зажатию мыши.
	------------------------------------------------------------------*/
	private reactAdd(event: MouseEvent){
		
		// клики только по канвасу
		if((<HTMLElement>event.target).id != this.canvas.node.id) { this.clickState = "UP"; return; }
		
		// глобальная матрица #seat
		let globalMatrix = this.getGlobalMatrix();
		
		// стартовая позиция мыши по клику с учётом глобальной матрицы #seat
		let startX = (event.offsetX - globalMatrix[4]) / globalMatrix[0];
		let startY = (event.offsetY - globalMatrix[5]) / globalMatrix[3];

		// применяем сетку
		if(this.grid.active){
			startX = Math.round(startX / this.grid.size) * this.grid.size;
			startY = Math.round(startY / this.grid.size) * this.grid.size;
		}
		
		// размеры матрицы сидений
		let sizeX = 1, sizeY = 1; 
		
		// временный массив для добавляемых мест
		let tempSeats: Array<Seat> = new Array();
	
		// сохраняем контекст
		let that = this;
		
		// добавляем хотя бы одно место по mousedown
		if(!tempSeats.length) tempSeats[tempSeats.length] = this.addSeat({x: startX, y: startY, canvas: this.seats.temp});
		
		// дизаблим кнопку корзины, нумерацию рядов / мест и т.п.
		this.hideSelectControlls();
		
		// слушаем движение и отжатие мыши
		this.editor.addEventListener("mousemove", mousemove);
		this.editor.addEventListener('mouseup', mouseup);

		function mousemove(event: MouseEvent){
			// мышь в движении
			that.clickState = "MOVE";
			
			// разница стартовой позиции клика с текущей с учётом глобальной матрицы #seat
			let diffX = (event.offsetX - globalMatrix[4]) / globalMatrix[0] - startX;
			let diffY = (event.offsetY - globalMatrix[5]) / globalMatrix[3] - startY;
			
			// новый размер матрицы сидений
			let newSizeX = Math.abs(Math.round(diffX / that.grid.size)) + 1;
			let newSizeY = Math.abs(Math.round(diffY / that.grid.size)) + 1;
			
			// обновляем информацию о размере сетки
			that.gridInfo.classList.remove('d-none');
			that.gridInfo.textContent = `${newSizeX} x ${newSizeY}`;
			that.gridInfo.setAttribute('style', '' + 
				'left: ' + (event.clientX + (diffX < 0 ? -(that.gridInfo.offsetWidth + 15): 20)) + 'px; ' + 
				'top: ' + (event.clientY + (diffY < 0 ? -(that.gridInfo.offsetHeight + 15): 20)) + 'px');
				
			that.gridInfo.classList.remove('border-lt');
			that.gridInfo.classList.remove('border-lb');
			that.gridInfo.classList.remove('border-rt');
			that.gridInfo.classList.remove('border-rb');
			
			switch((diffX < 0) + ' ' + (diffY < 0)){
				case 'false false' : that.gridInfo.classList.add('border-lt'); break;
				case 'false true'  : that.gridInfo.classList.add('border-lb'); break;
				case 'true false'  : that.gridInfo.classList.add('border-rt'); break;
				case 'true true'   : that.gridInfo.classList.add('border-rb'); break;
			}
			
			// немного оптимизации, чтобы не перерисовывать когда кол-во мест не поменялось
			if(newSizeX == sizeX && newSizeY == sizeY) { return; }
			
			// и ограничение по ширине и длинне
			newSizeX = newSizeX > 50 ? 50 : newSizeX;
			newSizeY = newSizeY > 50 ? 50 : newSizeY;
			
			// обновляем счётчик кол-ва сидений
			that.seats.count = that.seats.list.length;
			
			// чистим #seats>#temp, очищаем ненужный массив перед отрисовкой
			that.seats.temp.clear();
			tempSeats = tempSeats.slice(tempSeats.length);
			
			// рассчёт позиции, отрисовка и сохранение каждого места
			for(let x = 0; x < newSizeX; x++){
				for(let y = 0; y < newSizeY; y++){
					
					let seatX = x * that.grid.size * (diffX < 0 ? -1 : 1) + startX;
					let seatY = y * that.grid.size * (diffY < 0 ? -1 : 1) + startY;
					
					tempSeats[tempSeats.length] = that.addSeat({x: seatX, y: seatY, canvas: that.seats.temp});
				}
			}
			
			// храним старый размер матрицы
			sizeX = newSizeX;
			sizeY = newSizeY;
		}

		function mouseup(){
			// удаляем слушатели
			that.editor.removeEventListener("mouseup", mouseup);
			that.editor.removeEventListener("mousemove", mousemove);
			
			// мышь отжата
			that.clickState = "UP";
			
			// обновляем счётчик кол-ва сидений (странная конструкция, не помню, зачем именно так)
			that.seats.count = tempSeats.length == that.seats.list.length ? 0 : that.seats.list.length;
			
			for(let i in tempSeats){
				// наслоения мест по сетке
				let overlapping = that.seats.map[tempSeats[i].x + 'x' + tempSeats[i].y] ? true : false;
						
				// пишем сидения в общий массив, добавляем на схему, добавляем на seatsMap
				if(!overlapping){
					that.seats.list[that.seats.list.length] = that.addSeat({x: tempSeats[i].x, y: tempSeats[i].y, canvas: that.seats.actual});
					that.seats.map[tempSeats[i].x + 'x' + tempSeats[i].y] = true;
				}
			}
			
			// чистим #seats>#temp, очищаем ненужный массив
			that.seats.temp.clear();
			tempSeats = tempSeats.slice(tempSeats.length);
			
			// скрываем инфо о размере добавляемой сетки
			that.gridInfo.classList.add('d-none');
		}
	}

	private reactSelect(event: MouseEvent){
		
		let that = this;
		
		// глобальная матрица #seat
		let globalMatrix = this.getGlobalMatrix();
		
		// стартовая позиция мыши по клику
		let startX = event.offsetX;
		let startY = event.offsetY;
		
		//SNAP-объект для бокса выделения
		let selector = this.canvas.rect(startX, startY, 0, 0);
		selector.attr({
			id: "selector",
			fill: "rgba(0, 120, 215, 0.25)",
			stroke: "rgb(0, 120, 215)"
		});
		
		// слушаем движение и отжатие мыши
		this.editor.addEventListener("mousemove", mousemove);
		this.editor.addEventListener('mouseup', mouseup);
		
		function mousemove(event: MouseEvent){
			// мышь в движении
			that.clickState = "MOVE";
			
			// разница стартовой позиции клика с текущей
			let diffX = event.offsetX - startX; // + globalMatrix[4];
			let diffY = event.offsetY - startY; // + globalMatrix[5];
			
			selector.attr({
				x: diffX > 0 ? startX : event.offsetX,
				y: diffY > 0 ? startY : event.offsetY,
				width: Math.abs(diffX),
				height: Math.abs(diffY)
			});
		}
		
		function mouseup(){
			// удаляем слушатели
			that.editor.removeEventListener("mouseup", mouseup);
			that.editor.removeEventListener("mousemove", mousemove);
			
			// мышь отжата
			that.clickState = "UP";
			
			// проверяем что попадает в выделение, добавляем это в группу seats.selected.group
			let bBoxSelector = selector.getBBox();
			
			// получаем все места которые входят в выделение
			let canvas = that.canvas.node;
			let border = selector.node.getBBox();
			let container = <SVGElement>(document.querySelector('#seats #actual'));
			
			let selectedSeats = canvas.getIntersectionList(border, container);
			
			for (let seat of selectedSeats) {
				that.seats.selected.group.add( Snap(seat) );
			}
			
			// Обводка выделенных мест
			if (that.seats.selected.group[0]) {
				let borderBBox = that.seats.selected.group.getBBox();
				let border = that.seats.selected.border = that.seats.selected.group.rect(borderBBox.x - 5, borderBBox.y - 5, borderBBox.width + 10, borderBBox.height + 10);
				
				border.attr({ id: "selected__outline" });
				
				// если ничего не выделено -- удаляем обводку у выделенных мест, скрываем селектор категорий
				if(borderBBox.x == 0 && borderBBox.y == 0){
					border.remove();
					
					that.hideSelectControlls();
					
					border.node.removeEventListener('mouseenter', event => that.initResizer.call(that, event));
				}
				// иначе -- показываем селектор категорий, и навешиваем слушатель на ресайзер (пока не используется)
				else {
					that.showSelectControlls();
					border.node.addEventListener('mouseenter', event => that.initResizer.call(that, event));
				}
			}
			
			// удаляем селектор
			selector.remove();
		}
		
	}
	
	/* появляется при наведении на ресайзер */
	private initResizer(event: MouseEvent){
		let that = this;
		let target = <HTMLElement>event.target;
		let border = this.seats.selected.border;
		let borderBBox = border.getBBox();
		
		//доделать
		/*that.seats.selected.group.g(
			this.canvas.circle(-2, -2, 4).attr("fill", "red"),
			this.canvas.circle(-2, borderBBox.y + 2, 4).attr("fill", "red"),
			this.canvas.circle(borderBBox.x + 2, -2, 4).attr("fill", "red"),
			this.canvas.circle(borderBBox.x + 2, borderBBox.y + 2, 4).attr("fill", "red"),
			that.seats.selected.border
		);*/
		
		
		
		
		
		// добавляем слушатели
		target.addEventListener('mouseout', mouseout);		
		target.addEventListener('mousemove', mousemove);
		
		function mousemove(){
			
		}
		
		function mouseout(){			
			// удаляем слушатели
			target.removeEventListener('mouseenter', that.initResizer);			
			target.removeEventListener('mousemove', mousemove);
			target.removeEventListener('mouseout', event => that.initResizer.call(that, event));
			
			
		}
	}
	
	private reactMove(event: MouseEvent){
		let that = this;
		let diffX, diffY;
		
		// глобальная матрица #seat
		let globalMatrix = this.getGlobalMatrix();
		
		// локальная матрица #selected
		let localMatrix = this.getSelectedMatrix();
		
		// отступ слева (из-за сайдбара)
		let offset = {
			left: (<DOMRect>that.editor.getBoundingClientRect()).x,
			top: (<DOMRect>that.editor.getBoundingClientRect()).y
		};
		
		// последние валидные координаты при перемещении мест
		let lastGoodCoords = { x: 0, y: 0 }
		
		// флаг пересечений
		let overlapping = false;		
		
		// проверяет не валидность координат места: выходит ли за границы редактора && является ли местом 
		let areCoordsInvalid = function(x, y) {
			if (x < offset.left || y < offset.top) return true;
			try   { return (<HTMLElement>Snap.getElementByPoint(x, y).node).getAttribute('class') == 'seat'; }
			catch { return true; };
		}
		
		// сетка с учётом матрицы		
		let gridSizeX = localMatrix[0] * that.grid.size;
		let gridSizeY = localMatrix[3] * that.grid.size;
		
		// стартовая позиция мыши по клику с учётом глобальной матрицы #seat
		let startX = (event.offsetX - globalMatrix[4]) / globalMatrix[0];
		let startY = (event.offsetY - globalMatrix[5]) / globalMatrix[3];
		
		// без отключения сетки по контролу не помню, что делает
		/*if (that.keysPressed["SHIFT"]){ //!that.keysPressed["CTRL"]
			startX += (startX % gridSizeX > gridSizeX ? 1: -1) * startX % gridSizeX;
			startY += (startY % gridSizeY > gridSizeY ? 1: -1) * startY % gridSizeY;
		}*/
		
		// мышь зажата
		that.clickState = "DOWN";
		
		// слушаем движение и отжатие мыши
		this.editor.addEventListener("mousemove", mousemove);
		this.editor.addEventListener('mouseup', mouseup);		
		
		function mousemove(event: MouseEvent){
			//мышь в движении
			that.clickState = "MOVE";
			
			// разница стартовой позиции клика с текущей с учётом глобальной матрицы #seat
			diffX = (event.offsetX - globalMatrix[4]) / globalMatrix[0] - startX;
			diffY = (event.offsetY - globalMatrix[5]) / globalMatrix[3] - startY;
			
			// фиксируем одну ось если зажат SHIFT
			if (that.keysPressed["SHIFT"] && Math.abs(diffX) > Math.abs(diffY)) diffY = 0; else 
			if (that.keysPressed["SHIFT"] && Math.abs(diffX) <= Math.abs(diffY)) diffX = 0;
				
			// ровнять по сетке, когда не нажат CTRL
			if (!that.keysPressed["CTRL"]){
				// координаты одного места для выравнивания по сетке
				let x = +that.seats.selected.group[0].attr('cx');
				let y = +that.seats.selected.group[0].attr('cy');
				
				diffX += ((x + diffX) % gridSizeX > gridSizeX ? 1: -1) * (x + diffX) % gridSizeX;
				diffY += ((y + diffY) % gridSizeY > gridSizeY ? 1: -1) * (y + diffY) % gridSizeY;
			}
			
			overlapping = false;
			
			for (let i = 0; that.seats.selected.group[i]; i++){
				let seat = that.seats.selected.group[i];
				// новые координаты места относительно окна
				let x = offset.left + (+seat.attr('cx') + diffX) * globalMatrix[0] + globalMatrix[4];
				let y = offset.top + (+seat.attr('cy') + diffY) * globalMatrix[3] + globalMatrix[5];
				//let r = +seat.attr('r');
			
				// проверяем валидность координат по 9 точкам для точности
				/*overlapping += +(
					areCoordsInvalid(x - r, y - r) || areCoordsInvalid(x, y - r) || areCoordsInvalid(x + r, y - r) || 
					areCoordsInvalid(x - r, y)     || areCoordsInvalid(x, y)     || areCoordsInvalid(x + r, y)     ||
					areCoordsInvalid(x - r, y + r) || areCoordsInvalid(x, y + r) || areCoordsInvalid(x + r, y + r));*/
			
				// одна точка для оптимизации
				overlapping = areCoordsInvalid(x, y);
				if(overlapping) break;
			};
			
			// Если перекрытия нет -- обновить "хорошие" координаты и контроллим класс селектора
			if(!overlapping) { 
				that.seats.selected.border.removeClass('overlapping');
				lastGoodCoords = { 
					x: localMatrix[4] + diffX, 
					y: localMatrix[5] + diffY
				};
			}
			else {
				that.seats.selected.border.addClass('overlapping');
			}
			
			// пока двигаем перекрытие может быть, в любом случае по mouseup места встанут только на "хорошие" коорднаты
			that.seats.selected.group.attr('transform', `matrix(1, 0, 0, 1, ${ localMatrix[4] + diffX }, ${ localMatrix[5] + diffY })`);
		}
		
		function mouseup(event: MouseEvent){			
			// удаляем слушатели
			that.editor.removeEventListener("mousemove", mousemove);
			that.editor.removeEventListener("mouseup", mouseup);
			
			// обрабатывать только после движения
			if(that.clickState != "MOVE") { that.clickState = "UP"; return; }			
			
			
			// сетка с учётом матрицы		
			let gridSizeX = localMatrix[0] * that.grid.size;
			let gridSizeY = localMatrix[3] * that.grid.size;
			
			// ставим места только на "хорошие" координаты
			diffX = lastGoodCoords.x;
			diffY = lastGoodCoords.y;			
			
			// обновляем матрицу занятых сидений, и двигаем каждое сидение			
			for (let i = 0; that.seats.selected.group[i]; i++) {
				let seat = that.seats.selected.group[i];
				let x = +seat.attr('cx');
				let y = +seat.attr('cy');
				
				// обновляем карту
				that.seats.map[x + 'x' + y] = false;
				that.seats.map[(x + diffX) + 'x' + (y + diffY)] = true;
				
				// двигаем место
				seat.attr('cx', x + diffX);
				seat.attr('cy', y + diffY);
			};
			
			// обновляем локальную матрицу после передвижения
			localMatrix = that.getSelectedMatrix();
			
			// селектору новые координаты
			let border = that.seats.selected.border;
			border.attr({x: +border.attr('x') + diffX, y: +border.attr('y') + diffY});
			
			// сбросить класс пересечения с селектора
			border.removeClass('overlapping')
			
			that.seats.selected.group.attr('transform','');
			
			// мышь отжата
			that.clickState = "UP";
		}
	}

	private reactHand(event: MouseEvent){
		let that = this;
		
		// глобальная матрица #seat
		let globalMatrix = this.getGlobalMatrix();
		
		// стартовая позиция мыши по клику 
		let startX = event.offsetX;
		let startY = event.offsetY;
		
		// CSS { cursor: grabbing } 
		this.editor.classList.add('editor-grabbing');
		
		// скрыть селектор категорий если нет выделенных мест / но только в режиме HAND
		if(!document.querySelector('#selected__outline') && that.mode == "HAND"){
		this.hideSelectControlls();
	}
			
		
		// слушаем движение и отжатие мыши
		this.editor.addEventListener("mousemove", mousemove);
		this.editor.addEventListener('mouseup', mouseup);
		
		function mousemove(event: MouseEvent){
			
			// мышь в движении
			that.clickState = "MOVE";
			
			// разница стартовой позиции клика с текущей
			let diffX = event.offsetX - startX + globalMatrix[4];
			let diffY = event.offsetY - startY + globalMatrix[5];
			
			that.fitBGDots();
			
			// двигаем выбранную группу
			that.seats.container.attr('transform', `matrix(${globalMatrix[0]}, 0, 0, ${globalMatrix[3]}, ${diffX}, ${diffY})`);
		}
		function mouseup(){
			// удаляем слушатели
			that.editor.removeEventListener("mouseup", mouseup);
			that.editor.removeEventListener("mousemove", mousemove);
			
			that.clickState = "UP";
			
			// обнуляем матрицу transform()
			that.seats.list.forEach(seat => seat.applyMatrix());
						
			that.editor.classList.remove('editor-grabbing');
			
		}
	}

	private addSeat(config){
		let settings = {
			x: config.x,
			y: config.y,
			target: config.canvas || this.seats.container,
			id: "seat-" + this.seats.count++,
			fill: this.categories.active.color,
			category: config.category || this.categories.active.id,
			row: config.row || 1,
			seat: config.seat || 1
		}
		
		let seat = new Seat(settings);
		let that = this;
		
		seat.element.mousemove(
			function(e){
				if (that.clickState != "UP") return;
				
				that.seatInfo.classList.remove('d-none');
				//console.log(e);
				
				let category = that.searchCategory({id: e.target.getAttribute("data-category")});
				
				(<HTMLElement>that.seatInfo.querySelector('.seat-info__category')).textContent = category.name;
				(<HTMLElement>that.seatInfo.querySelector('.seat-info__category')).setAttribute('style', 'color: ' + category.color);
				(<HTMLElement>that.seatInfo.querySelector('.seat-info__row')).textContent      = e.target.getAttribute("data-row");
				(<HTMLElement>that.seatInfo.querySelector('.seat-info__seat')).textContent     = e.target.getAttribute("data-seat");
				
				
				
				that.seatInfo.setAttribute('style', 'left: ' + (e.clientX - that.seatInfo.offsetWidth / 2 + 4) + 'px; top: ' + (e.clientY + 30) + 'px');
				
				//console.log("Seat: " + e.target.getAttribute("data-seat") + ", " + "Row: " + e.target.getAttribute("data-row"));
			}
		);
		
		seat.element.mouseout(function(e){
				that.seatInfo.classList.add('d-none');
				//console.log("Seat: " + e.target.getAttribute("data-seat") + ", " + "Row: " + e.target.getAttribute("data-row"));
			}
		)
		
		return seat;
		
		
	}

	private removeSeats() {
		// console.log(this.seats.actual.node)
		for (let seat of this.seats.list) this.seats.selected.group.add( Snap(seat.element.node) )
		this.clearSelected(true)
	}

	public setMode(mode: String){
		this.mode = mode;
		
		if(mode == "HAND") this.editor.classList.add('editor-grab');
		else this.editor.classList.remove('editor-grab');		
	}
	
	private getGlobalMatrix(){
		let matrix = this.seats.container.attr('transform').toString() != ''
			? this.seats.container.attr('transform').toString().slice(1).split(',')
			: [1, 0, 0, 1, 0, 0];
			
		for(var i in matrix) matrix[i] = +matrix[i];
		return matrix;
	}
	
	private getSelectedMatrix(){
		let matrix = this.seats.selected.group.attr('transform').toString() != ''
			? this.seats.selected.group.attr('transform').toString().slice(1).split(',')
			: [1, 0, 0, 1, 0, 0];
			
		for(var i in matrix) matrix[i] = +matrix[i];
		return matrix;
	}
	
	public zoom(data: String, x: Number = 0, y: Number = 0){
		
		let outerBox = {
			x: 15,   y: 15,
			w: this.canvas.node.clientWidth - 30,
			h: this.canvas.node.clientHeight - 30
		}
		let contentBox = this.seats.actual.getBBox();
		
		if(data == '='){
			
			let factor = Math.min(outerBox.w / contentBox.w, outerBox.h / contentBox.h);
			
			if(factor > 2) factor = 2;
			if(factor < 0.1) factor = 0.1;
			
			let xShift = outerBox.x - contentBox.x * factor;
			let yShift = outerBox.y - contentBox.y * factor;
						
			this.seats.container.attr('transform', `matrix(${factor} 0 0 ${factor} ${xShift} ${yShift})`);
			
			this.fitBGDots();
			
			return factor;
		}
		
		let addition = data == '+' ? 0.1 : (data == '-' ? -0.1 : 0);
		let matrix = this.getGlobalMatrix();
		
		matrix[0] += addition;
		matrix[3] += addition;
		
		matrix[0] = matrix[0] > 2 ? 2: matrix[0];
		matrix[3] = matrix[3] > 2 ? 2: matrix[3];
		matrix[0] = matrix[0] < 0.2 ? 0.2: matrix[0];
		matrix[3] = matrix[3] < 0.2 ? 0.2: matrix[3];
		
		// console.log(contentBox);
		if(x == 0 && matrix[0] <= 2 && matrix[0] >= 0.2){
			if(matrix[0] == 2 || matrix[0] == 0.2) return;
		}
		if(y == 0 && matrix[3] <= 2 && matrix[3] >= 0.2){
			if(matrix[3] == 2 || matrix[3] == 0.2) return;
		}

		this.seats.container.attr('transform', `matrix(${matrix})`);
		this.fitBGDots();
		
		return matrix[0];
	}
	
	private fitBGDots(){
		let gm = this.getGlobalMatrix();
		
		let size = gm[0] * this.grid.size;
		let x = gm[4] + size / 2;
		let y = gm[5] + size / 2;
		
		this.editor.setAttribute('style', `
			background-size: ${size}px;
			background-position: ${x}px ${y}px;
		`);
	}
	
	public setCategory(category: String){
		
	}
	
	private clearSelected(remove:boolean = false){
		if(remove){
			
			this.hideSelectControlls();
			
			// проход по локальному массиву seats.selected
			for (let i = 0; this.seats.selected.group[i]; i++) {
				let seat = this.seats.selected.group[i];
				
				this.seats.map[+seat.attr('cx') + 'x' + +seat.attr('cy')] = false; // удаляем место с карты мест
				seat.addClass('removing','removing'); // ставим флаг, что место подлежит удалению
			};
			
			// удалить из глобального массива seats 
				for(let i = 0; i < this.seats.list.length; i++){
					if(this.seats.list[i].element.hasClass('removing')){
						this.seats.list[i].destroy(); // удаляем сам элемент
						this.seats.list.splice(i--, 1); // удаляем ссылку на него
				}

			}
		}
		else {
			// просто перемещаем места в актуальные
			for(let i = 0; this.seats.selected.group[i]; i++){
				if(this.seats.selected.group[i].type == 'rect') continue;
				this.seats.actual.add(this.seats.selected.group[i]);
			}
		}
		
		this.seats.selected.group.clear();
		
	}
	
	public setActiveCategory(settings){
		this.categories.active = this.searchCategory(settings);
	}
	
	public editCategory(old, settings){
		var category = this.searchCategory(old);
		
		category.id = settings.id;
		category.name = settings.name;
		category.color = settings.color;
		
		let seats = <NodeListOf<Element>>document.querySelectorAll('circle[data-category="' + old.id + '"]');
		
		for (let i = 0; i < seats.length; i++){
			seats[i].setAttribute('data-category', settings.id);
			seats[i].setAttribute('fill', settings.color);
		}
	}
	
	public addCategory(settings: any){
		var category = new Category(settings);
		this.categories.list.push(category);
	}
	
	public applyCategoryToSelected(settings){
		
		let seats = <NodeListOf<Element>>document.querySelectorAll('.selected .seat');
		
		for (let i = 0; i < seats.length; i++){
			seats[i].setAttribute('data-category', settings.id);
			seats[i].setAttribute('fill', settings.color);
		}
	}
	
	public searchCategory(search):Category{
		for (let i = 0; i <= this.categories.list.length; i++){
			let category = this.categories.list[i];
				
			if(search.id == category.id)
			return category;
		}
		
		return this.categories.list[0];
		
	}
	
	public numerateSelectedSeats (seatsNumeration, rowsNumeration, seatsNumerationFrom, rowsNumerationFrom, seatsNumerationStep, rowsNumerationStep) {
		// получаем список мест в виде массива (Array) элементов, вместо списка (NodeListOf)
		let seats = Array.prototype.slice.call(<NodeListOf<Element>>document.querySelectorAll('.selected .seat'));
		
		// "переломный" аттрибут cx или cy по смене которого при переборе увеличивается ряд и места сбрасываются на "0"
		let attr; 
		
		/**
			* далее магия сортировки упорядочивает массив по рядам (первично), местам (вторично),
			* сравнивая координаты мест, учитывая направление нумерации рядов и мест
			* как работает .sort() http://javascript.ru/array/sort
			*
			* @param {Array} a - массив первых элементов в парах сравнения, задающий порядок сравнения координат по координате attr[0]
			* @param {Array} b - массив вторых элементов в парах сравнения, задающий порядок сравнения координат по координате attr[1]
			* @param {Array} attr - массив аттрибутов определяющих координаты места, задающий порядок сравнения по координатам
			* @returns {number} - [-1, 1, 0]
			*/
		function sortSeats(a, b, attrs) {
			return a[0].getAttribute(attrs[0]) - b[0].getAttribute(attrs[0]) || a[1].getAttribute(attrs[1]) - b[1].getAttribute(attrs[1]);
		}
		
		// порядок сортировки определяется порядком аттрибутов переданных в сортирующую функцию
		switch(seatsNumeration + "," + rowsNumeration){
			case "1,3": seats = seats.sort( (a, b) => sortSeats ([a, a], [b, b], ['cy', 'cx'])); attr = "cy"; break; // right bottom
			case "1,4": seats = seats.sort( (a, b) => sortSeats ([b, a], [a, b], ['cy', 'cx'])); attr = "cy"; break; // right top
			case "2,3": seats = seats.sort( (a, b) => sortSeats ([a, b], [b, a], ['cy', 'cx'])); attr = "cy"; break; // left bottom
			case "2,4": seats = seats.sort( (a, b) => sortSeats ([b, b], [a, a], ['cy', 'cx'])); attr = "cy"; break; // left top
			case "3,1": seats = seats.sort( (a, b) => sortSeats ([a, a], [b, b], ['cx', 'cy'])); attr = "cx"; break; // bottom right
			case "3,2": seats = seats.sort( (a, b) => sortSeats ([b, a], [a, b], ['cx', 'cy'])); attr = "cx"; break; // bottom left
			case "4,1": seats = seats.sort( (a, b) => sortSeats ([a, b], [b, a], ['cx', 'cy'])); attr = "cx"; break; // top right
			case "4,2": seats = seats.sort( (a, b) => sortSeats ([b, b], [a, a], ['cx', 'cy'])); attr = "cx"; break; // top left
		}
		
		seats[0].setAttribute('data-seat', seatsNumerationFrom);
		seats[0].setAttribute('data-row', rowsNumerationFrom);
		
		for(let i = 1, row = rowsNumerationFrom, seat = seatsNumerationFrom; i < seats.length; i++){
			if (seats[i].getAttribute(attr) != seats[i - 1].getAttribute(attr)) {
				seat = seatsNumerationFrom - seatsNumerationStep;
				row += rowsNumerationStep;
			}
			seat += seatsNumerationStep
			seats[i].setAttribute('data-seat', seat);
			seats[i].setAttribute('data-row', row);
		}
		
	}
	
	public hideSelectControlls(){
		this.toggleSelectControlls('disable');
	}
	public showSelectControlls(){
		this.toggleSelectControlls('enable');
	}
	
	private toggleSelectControlls(mode){
		let applyToSelectedButton = <HTMLElement>document.querySelector('.apply-to-select');
		let numeration = <HTMLElement>document.querySelector('.sidebar__numeration');
		let removeButton = <HTMLElement>document.querySelector('.sidebar__remove-button');
		
		if(mode == 'enable'){
			applyToSelectedButton.classList.remove('disabled');
			numeration.classList.remove('disabled');
			removeButton.classList.remove('disabled');
		} else if (mode == 'disable'){
			applyToSelectedButton.classList.add('disabled');
			numeration.classList.add('disabled');
			removeButton.classList.add('disabled');
		}
	}
	
	private move(direction: String){
		let seats = this.seats.selected.group;
		
		if(seats.length == 0 || this.clickState != "UP") return;
		
		let diffX, diffY;
		
		switch(direction){
			case "LEFT":   diffX = -25; diffY =   0; break;
			case "RIGHT":  diffX =  25; diffY =   0; break;
			case "TOP":    diffX =   0; diffY = -25; break;
			case "BOTTOM": diffX =   0; diffY =  25; break;
		}
		
		// глобальная матрица #seat
		let globalMatrix = this.getGlobalMatrix();
		
		// локальная матрица #selected
		let localMatrix = this.getSelectedMatrix();
		
		// отступ слева (из-за сайдбара)
		let offset = {
			left: (<DOMRect>this.editor.getBoundingClientRect()).x,
			top: (<DOMRect>this.editor.getBoundingClientRect()).y
		};
		
		let overlapping = false;
		
		// проверяет не валидность координат места: выходит ли за границы редактора && является ли местом 
		let areCoordsInvalid = function(x, y) {
			if (x < offset.left || y < offset.top) return true;
			try   { return (<HTMLElement>Snap.getElementByPoint(x, y).node).getAttribute('class') == 'seat'; }
			catch { return true; };
		}
		
		for (let i = 0; seats[i]; i++){
			let seat = this.seats.selected.group[i];
			// новые координаты места относительно окна
			let x = offset.left + (+seat.attr('cx') + diffX) * globalMatrix[0] + globalMatrix[4];
			let y = offset.top + (+seat.attr('cy') + diffY) * globalMatrix[3] + globalMatrix[5];
			
			overlapping = areCoordsInvalid(x, y);
			if(overlapping) break;
		};
		
		if(!overlapping){
			// пока двигаем перекрытие может быть, в любом случае по mouseup места встанут только на "хорошие" коорднаты
			this.seats.selected.group.attr('transform', `matrix(1, 0, 0, 1, ${ localMatrix[4] + diffX }, ${ localMatrix[5] + diffY })`);
			
			// обновляем матрицу занятых сидений, и двигаем каждое сидение			
			for (let i = 0; seats[i]; i++) {
				let seat = this.seats.selected.group[i];
				let x = +seat.attr('cx');
				let y = +seat.attr('cy');
				
				// обновляем карту
				this.seats.map[x + 'x' + y] = false;
				this.seats.map[(x + diffX) + 'x' + (y + diffY)] = true;
				
				// двигаем место
				seat.attr('cx', x + diffX);
				seat.attr('cy', y + diffY);
			};
			// селектору новые координаты
			let border = this.seats.selected.border;
			border.attr({x: +border.attr('x') + diffX, y: +border.attr('y') + diffY});
			this.seats.selected.group.attr('transform','');
		}
	}
	
	public export(format, width, height){
		
		// очищаем выделение, чтобы оно не попало в экспортированную картинку
		this.clearSelected();
		
		// параметры картинки на выходе, в которую впишется контент
		let outerBox = {
			x: 15,   y: 15,
			w: width - 30,
			h: height - 30
		}
		// bbox контейнера всех мест
		let contentBox = this.seats.actual.getBBox();
		
		// множитель, определяющий сдвиг и зум контейнера с местами для вписания контента в размеры выходной картинки (или json'а)
		let factor = Math.min(outerBox.w / contentBox.w, outerBox.h / contentBox.h);
		
		// старая матрица, чтобы изменения не были заметны
		let oldMatrix = this.getGlobalMatrix();
		this.seats.container.attr('transform', `matrix(${factor} 0 0 ${factor} ${outerBox.x - contentBox.x * factor} ${outerBox.y - contentBox.y * factor})`);
		
		// для json'а сохраняем матрицу, необходимую для вписания мест в выходное изображение
		let newMatrix = this.getGlobalMatrix();
		newMatrix = newMatrix.map(x => Math.round(x * 1000) / 1000);
		
		// текст svg готов
		let svg = this.canvas.node.outerHTML;
		svg = svg.replace('<svg', `<svg width="${width}" height="${height}"`);
		
		// возвращаем старую матрицу
		this.seats.container.attr('transform', `matrix(${oldMatrix})`);
		
		if(format == 'svg') {
			save(svg, 'svg');
		}
		
		if(format == 'json'){
			let json:any = { matrix: newMatrix, categories: this.categories.list, seats: [] };
			let seats = <NodeListOf<Element>>document.querySelectorAll('.seat');
			
			for (let i = 0; i < seats.length; i++){
				let seat = <HTMLElement>seats[i];
				json.seats[i] = {
					x: seat.getAttribute('cx'),
					y: seat.getAttribute('cy'),
					category: seat.getAttribute('data-category'),
					row: seat.getAttribute('data-row'),
					seat: seat.getAttribute('data-seat')
				};
			}
			
			save(JSON.stringify(json), 'json');
		}
		
		function save(str, type){
			str = encodeURIComponent(str);
			
			var url = 'save.php';
			
			var form = <HTMLFormElement>document.createElement('form');
			form.setAttribute('method', 'POST');
			form.setAttribute('action', url);
			
			let inputType = document.createElement('input');
			inputType.setAttribute('type', 'hidden');
			inputType.setAttribute('name', 'type');
			inputType.setAttribute('value', type);
			
			let inputContent = document.createElement('input');
			inputContent.setAttribute('type', 'hidden');
			inputContent.setAttribute('name', 'content');
			inputContent.setAttribute('value', str);
			
			form.appendChild(inputType);
			form.appendChild(inputContent);
			
			(<HTMLElement>document.querySelector('body')).appendChild(form);
			form.submit();
			form.remove();
		}
	}

	private uploadScheme (event) {
		let file = event.target.files[0];
		let link = window.URL.createObjectURL(file);

		fetch(link)
			.then(response => response.json())
			.then(scheme => {
				if (!scheme.seats || !scheme.categories) {
					PNotify.alert('Схема не валидна');
					return;
				}

				// обновляем категории
				this.categories.list = [];
				for (let category of scheme.categories) this.addCategory(category)

				// обновляем категоррии в сайдбаре
				let categoriesContainer = <HTMLSelectElement>document.querySelector("#categories")
				
				// инициируем обновление категорий в сайдбаре
				categoriesContainer.dispatchEvent(new Event('update'))

				// удаляем места
				this.removeSeats()

				// добавляем места
				for (let seat of scheme.seats) {
					// ставим категорию места активной
					this.setActiveCategory(this.searchCategory({id: seat.category}))

					// добавляем место на схему
					this.seats.list[this.seats.list.length] = this.addSeat({x: seat.x, y: seat.y, category: seat.category, row: seat.row, seat: seat.seat, canvas: this.seats.actual})
					this.seats.map[seat.x + 'x' + seat.y] = true;
				}


				// инициализируем обновление активной категории в сайдбаре
				categoriesContainer.dispatchEvent(new Event('change'));

				PNotify.success('Схема успешно загружена');

				// // обновляем матрицу позиционирования
				// this.seats.container.attr('transform', `matrix(${scheme.matrix.join(',')}`);
			})

	}
}
